import React, {Component} from "react";
import {
    convertSchool,
    createDefaultImpersonalSchoolsRequest,
    CreateImpersonalSchoolsRequest,
    exportSchool,
    getSchoolButtonsAdaptor,
    getSchoolShortKVList,
    getTeachersKV,
    ISchool,
    School,
    SchoolSearchRequest,
    SchoolsMetric
} from "../../api/School";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Button, Callout, Card, Elevation} from "@blueprintjs/core";
import KeyValueViewer from "../viewers/KeyValueViewer";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import PostRequestForm from "../forms/PostRequestForm";
import {DataType} from "../../actions/data";
import FormButton from "../forms/FormButton";
import {CSVLink} from 'react-csv';

import {
    add_teacher,
    copy_ids,
    create_impersonal_schools,
    create_teacher_account,
    created_impersonal_schools,
    data_copied,
    download_impersonal_schools,
    download_selected_schools,
    edit_label,
    edit_school,
    email_list,
    get_school_metrics,
    hide_school_metrics,
    information_about_schools,
    invalid_or_bounced,
    product_key,
    register_label,
    register_school,
    remove_teacher,
    school_search_help,
    schools_found,
    search_for_school,
    send_request,
    teacher_accounts,
    total_label,
    registered_this_month,
    registered_today,
    all_schools,
    impersonal_schools
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import RequestButton from "../RequestButton";
import {Method, Request} from "../../model/Request";
import {formatDate} from "../../service/formatDate";
import {Txt} from "../tools/Text";
import MailSendTool from "../tools/MailSendTool";
import {AppToaster} from "../../service/toaster";
import {isEmail} from "../../adaptors/FieldAdaptor";
import {MailResponse} from "../../api/MailRequest";
import MailSendResults from "../tools/MailSendResults";
import {schoolCSV} from "../../service/CsvMapping";
import {CreateTeacherRequest, DeleteTeacherRequest} from "../../api/Teacher";
import {PlainTextKV} from "../../model/KV";

interface SchoolInstrumentProps {
    schools ?:ISchool[];
    token : string;
    schoolsMetric ?: SchoolsMetric;
    vocabulary : Vocabulary;
    impersonalSchools ?: string[];
}

interface SchoolInstrumentState {
    school ?: School;
    schoolSearch ?: SchoolSearchRequest;
    impersonalSchoolRequest ?: CreateImpersonalSchoolsRequest;
    hideImpersonalResponse : boolean;
    hideSearch : boolean;
    expanded : Set<string>;
    hideMetrics : boolean;
    recipients : string [];
    invalidCount ?: number;
    mailResponse ?: MailResponse;
    waitForSchoolUpdate ?:boolean;
}

class SchoolInstrument extends Component<SchoolInstrumentProps, SchoolInstrumentState>{

    constructor(props : Readonly<SchoolInstrumentProps>) {
        super(props);
        this.state = {
            school : undefined,
            hideSearch : false,
            expanded : new Set<string>(),
            hideMetrics : false,
            recipients : [],
            hideImpersonalResponse : false
        }
    }

    handleEditSchool=(school : ISchool | undefined)=>{
        if(school === undefined){
            this.setState({...this.state,school});
            return;
        }

      this.setState({
          ...this.state,
            //schoolSearch : undefined,
            school : convertSchool(school)})
        console.log(this.state)
    };


    componentDidUpdate(prevProps: Readonly<SchoolInstrumentProps>, prevState: Readonly<SchoolInstrumentState>, snapshot?: any) {
        if(!this.state.waitForSchoolUpdate){
            return
        }

        if (prevState.school && this.state.school && this.props.schools && this.props.schools.length === 1 && this.state.school === prevState.school){
            this.setState({...this.state, waitForSchoolUpdate : false, school : convertSchool(this.props.schools[0])})
        }
    }

    handleMailTo =(recipients : string[]) =>{
        if (recipients.length === 0){
            AppToaster.show({
                icon: "warning-sign",
                intent: "danger",
                message: this.props.vocabulary[invalid_or_bounced]
            })

            return;
        }
        this.setState({...this.state, recipients})
    }

    onlyUniqueEmails = (school : string, index : number, schools : string[]) => {
        return schools.indexOf(school) === index;
    }

    handleMailToSelected =() =>{
        const count = this.props.schools!.length;
        const recipients = this.props.schools!
            .filter(school => !school.bounced)
            .map(school=> school.email)
            .filter(email => isEmail(email))
            .filter(this.onlyUniqueEmails);

        const invalidCount = count - recipients.length ;
        if (invalidCount !== this.state.invalidCount) this.setState({...this.state, invalidCount })

        this.handleMailTo(recipients);
    }

    handleCopyIds = ()=>{
        if(! this.props.schools || this.props.schools.length === 0){
            return
        }

        navigator.clipboard.writeText(this.props.schools.map(school => school.username).join(",")).then(
            ()=>{
                AppToaster.show({
                    icon: "warning-sign",
                    intent: "primary",
                    message: this.props.vocabulary[data_copied]
                })
            }
        )
    }

    handleCloseMail = ()=>{
        this.setState({...this.state,recipients : [],invalidCount : undefined})
    }

    handleMailSent = (msg ?: string)=>{
        if (msg) {
            this.setState({...this.state, mailResponse: JSON.parse(msg)})
        }
        this.handleCloseMail();
    }

    handleCloseMailResult = ()=>{
        this.setState({...this.state,mailResponse : undefined})
    }

    handleCreateSchool =()=>{
      this.handleEditSchool({
          username :'',
          name : '',
          city : '',
          address :'',
          email :'',
          phone : '',
          devices : 10,
          master : false,
          additionalComputers :0,
          description : 'RUS',
          region : '',
          contact : '',
          position : '',
          licenses : []
      })  ;
    };

    startSearch=()=>{
        this.setState({
            ...this.state,
            schoolSearch : new SchoolSearchRequest()
        })
        console.log(this.state)
    };

    stopSearch = ()=> {
        if (this.props.schools !== undefined)
            this.handleCloseEdit();
        this.setState({
            ...this.state,
            schoolSearch : undefined
        })
    };

    hideSearch=(hideSearch:boolean)=>{
        this.setState({
            ...this.state,
            hideSearch
        })
    };

    handleCloseEdit=()=>{
        this.handleEditSchool(undefined);
    };

    isExpanded =(school: ISchool):boolean => {
        return this.state.expanded.has(school.username)
    };

    handleExpand=(school : ISchool) =>{
      if (this.isExpanded(school)){
          this.state.expanded.delete(school.username);
      } else{
          this.state.expanded.add(school.username);
      }

      this.setState({
          ...this.state
      })
    };

    handleSave=()=> {
        this.setState({...this.state,waitForSchoolUpdate : true})
    };

    handleHideMetrics =()=>{
        this.setState({...this.state,hideMetrics : true})
    };

    handleShowMetrics =()=>{
        this.setState({...this.state,hideMetrics : false})
    };

    showImpersonalRequest = ()=> {
        this.setState({
            ...this.state,
            impersonalSchoolRequest : createDefaultImpersonalSchoolsRequest()
        })
    };

    hideImpersonalRequest = ()=> {
        this.setState({
            ...this.state,
            impersonalSchoolRequest : undefined
        })
    };

    hideImpersonalResponse = (hideImpersonalResponse : boolean)=> {
        this.setState({
            ...this.state,
            hideImpersonalResponse
        })
    };

    handleCopyImpersonalIds = ()=>{
        if (!this.props.impersonalSchools || this.props.impersonalSchools.length === 0)
            return

        navigator.clipboard.writeText(this.props.impersonalSchools.join(",")).then(
            ()=> {
                AppToaster.show({
                    icon: "warning-sign",
                    intent: "primary",
                    message: this.props.vocabulary[data_copied]
                })
            }
        )
    }

    render(){
        return <Card interactive={false} elevation={Elevation.ZERO} className='horizontal-margin'>
            <div className='tiles-container-start'>
                {  this.state.school === undefined ?
                    <Button
                        intent={Intent.SUCCESS}
                        icon={"add"}
                        className='tiles-one-third'
                        onClick={() => {
                        this.handleCreateSchool()
                    }}>{this.props.vocabulary[register_school]}</Button> : null
                }
                {  this.state.schoolSearch === undefined ?
                    <Button
                        intent={Intent.PRIMARY}
                        icon={"geosearch"}
                        className='tiles-one-third'
                        onClick={this.startSearch}>
                    {this.props.vocabulary[search_for_school]}</Button> : null
                }
                { this.props.schoolsMetric && ! this.state.hideMetrics ?
                <Button
                    intent={Intent.WARNING}
                    icon={"cross"}
                    className='tiles-one-third'
                    onClick={this.handleHideMetrics}>
                    {this.props.vocabulary[hide_school_metrics]}</Button> :
                <RequestButton
                    intent={Intent.WARNING}
                    icon='office'
                    type={DataType.School}
                    disabled={false}
                    className='tiles-one-third'
                    onSuccess={this.handleShowMetrics}
                    request={new Request(undefined, DataType.School + formatDate(new Date()), Method.GET, this.props.token)}>
                    {this.props.vocabulary[get_school_metrics]}
                </RequestButton>
                }
                {  this.state.impersonalSchoolRequest === undefined
                    ?   <Button intent={Intent.SUCCESS}
                        icon={"add"}
                        className='tiles-one-third'
                        onClick = {() => {
                            this.showImpersonalRequest()
                        }}>{this.props.vocabulary[create_impersonal_schools]}
                        </Button>
                    : null
                }
            </div>
            {
                this.state.schoolSearch === undefined ? null :
                <PostRequestForm
                    default={true}
                    intent={Intent.SUCCESS}
                    url= {DataType.SchoolAll}
                    label={new Txt().dict(search_for_school)}
                    type={DataType.SchoolAll}
                    help={school_search_help}
                    data={this.state.schoolSearch}
                    method={this.state.schoolSearch.getMethod()}
                    cancel={this.stopSearch}
                    buttonText={send_request}
                />
            }
            {
                this.state.school === undefined ? null :
                <div>
                    <PostRequestForm
                        default={true}
                        intent={Intent.PRIMARY}
                        url= {DataType.School}
                        label={new Txt().dict(this.state.school.isNew ? register_school : edit_school)}
                        type={this.state.school.getType()}
                        help={''}
                        data={this.state.school}
                        method={this.state.school.getMethod()}
                        cancel={this.handleCloseEdit}
                        buttonText={this.state.school.isNew ? register_label : edit_label}
                        ok ={this.handleSave}
                    />
                        <Card interactive={true}
                              title={this.props.vocabulary[teacher_accounts]}
                              elevation={Elevation.ZERO}
                            className='base-margin'>
                            <Button icon='user'  intent={Intent.SUCCESS} minimal={true} className='card--title-icon'>
                                {this.props.vocabulary[teacher_accounts]}
                            </Button>
                            {this.state.school.teachers ?
                                <div className='form--item-container'>
                                    {getTeachersKV(this.state.school.teachers).map((kv, index) => (
                                        <div className='form-item-element' key={index}>
                                            <KeyValueViewer
                                                token={this.props.token}
                                                kv={kv}/>
                                        </div>
                                    ))}
                                </div>
                                : null
                            }
                            <PostRequestForm
                                default={false}
                                intent={Intent.SUCCESS}
                                url= {DataType.SchoolTeacher}
                                label={new Txt().dict(create_teacher_account)}
                                type={this.state.school.getType()}
                                help={''}
                                data={new CreateTeacherRequest(this.state.school.username)}
                                method={Method.POST}
                                buttonText={register_label}
                            />
                            <PostRequestForm
                                default={false}
                                intent={Intent.PRIMARY}
                                url= {DataType.SchoolTeacher}
                                label={new Txt().dict(add_teacher)}
                                type={this.state.school.getType()}
                                help={''}
                                data={new CreateTeacherRequest(this.state.school.username,'','', false)}
                                method={Method.PUT}
                            />
                            <PostRequestForm
                                default={false}
                                intent={Intent.WARNING}
                                url= {DataType.SchoolTeacher}
                                label={new Txt().dict(remove_teacher)}
                                type={this.state.school.getType()}
                                help={''}
                                data={new DeleteTeacherRequest(this.state.school.username)}
                                method={Method.DELETE}
                            />

                        </Card>
                </div>
            }
            {
                    this.props.schools === undefined/* || this.state.school */? null :
                    <Card interactive={false} elevation={Elevation.THREE} className='instruments-cards--space tiles-container'>
                        <div className='form-item-row form--button-container'>
                            <Callout intent={Intent.PRIMARY}
                                     icon='office'
                                     className='card-caption bp3-text-large '
                                     title={this.props.vocabulary[schools_found]+" " +this.props.schools.length}
                            >
                                {this.props.schools.length === 0 ? null :
                                    <div >
                                        <CSVLink
                                            data={this.props.schools.map(exportSchool)}
                                            filename='SelectedSchools.csv'
                                            className ='inline common-margin'
                                            headers={schoolCSV}>
                                            <Button
                                                icon='import'
                                                intent={Intent.PRIMARY}
                                                onClick={()=>{}}>
                                                {this.props.vocabulary[download_selected_schools]}
                                            </Button>
                                        </CSVLink>
                                        <Button
                                            className ='inline common-margin'
                                            icon='envelope'
                                            intent={Intent.PRIMARY}
                                            onClick={this.handleMailToSelected}>
                                            {this.props.vocabulary[email_list]}
                                        </Button>
                                        <Button
                                            className ='inline common-margin'
                                            icon='clipboard'
                                            intent={Intent.PRIMARY}
                                            onClick={this.handleCopyIds}>
                                            { this.props.vocabulary[copy_ids]}
                                        </Button>
                                    </div>
                                }
                            </Callout>

                            {   this.state.hideSearch ?
                                <Button
                                    className='card-caption-button'
                                    icon='expand-all'
                                    intent={Intent.SUCCESS}
                                    onClick={()=>{this.hideSearch(false)}}/>
                                    :
                                <Button
                                    className='card-caption-button'
                                    icon='collapse-all'
                                    intent={Intent.DANGER}
                                    onClick={()=>{this.hideSearch(true)}}/>
                            }
                        </div>
                        {   this.state.hideSearch ? null :
                            this.props.schools.map((school, index) =>(
                            <Card interactive={true}
                                  elevation={Elevation.ONE}
                                  key={index}
                                  className={this.isExpanded(school) ? 'form-item-row form--item-container--columns':'tiles-item form--item-container'}>

                                {
                                    getSchoolShortKVList(school,this.isExpanded(school)).map(kv=>(
                                    <div className='form-item-element' key={kv.key}>
                                        <KeyValueViewer
                                            token={this.props.token}
                                            kv={kv}
                                        />
                                    </div>))
                                }
                                <div className={(this.isExpanded(school) ? 'full-row' : 'form-item-row' ) + ' form--center-button-container'}>
                                    {
                                        getSchoolButtonsAdaptor(school,
                                            this.handleEditSchool,
                                            this.handleExpand,
                                            this.isExpanded(school),
                                            this.handleMailTo).map((adaptor,index) =>
                                            <FormButton
                                                className='form-button-4'
                                                adaptor={adaptor}
                                                token={this.props.token}
                                                key={index}/>)
                                    }
                                </div>
                            </Card>))
                        }
                    </Card>
            }
            {
                this.props.schoolsMetric === undefined || this.state.hideMetrics ? null :
                <Callout
                    className={'instruments-cards--space '}
                    intent={Intent.SUCCESS}
                    icon='office'
                    title={this.props.vocabulary[information_about_schools]}
                ><div className={'tiles-container'}>
                    <table>
                      <thead>
                          <tr>
                            <th></th>
                            <th>{this.props.vocabulary[total_label]}</th>
                            <th>{this.props.vocabulary[registered_this_month]}</th>
                            <th>{this.props.vocabulary[registered_today]}</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <th>{this.props.vocabulary[all_schools]}</th>
                            <td>{this.props.schoolsMetric.allSchools.total}</td>
                            <td>{this.props.schoolsMetric.allSchools.thisMonth}</td>
                            <td>{this.props.schoolsMetric.allSchools.today}</td>
                          </tr>
                          <tr>
                            <th>{this.props.vocabulary[impersonal_schools]}</th>
                            <td>{this.props.schoolsMetric.impersSchools.total}</td>
                            <td>{this.props.schoolsMetric.impersSchools.thisMonth}</td>
                            <td>{this.props.schoolsMetric.impersSchools.today}</td>
                          </tr>
                      </tbody>
                    </table>
                    </div>
                </Callout>
            }
            {
                this.state.impersonalSchoolRequest === undefined ? null :
                    <PostRequestForm
                        default={true}
                        intent={Intent.SUCCESS}
                        url= {DataType.CreateImpersonalSchool}
                        label={new Txt().dict(create_impersonal_schools)}
                        type={DataType.CreateImpersonalSchool}
                        data={this.state.impersonalSchoolRequest}
                        method={this.state.impersonalSchoolRequest.getMethod()}
                        cancel={this.hideImpersonalRequest}
                        buttonText={send_request}
                    />
            }
            {
                this.props.impersonalSchools === undefined ? null :
                    <Card interactive={false} elevation={Elevation.THREE} className='instruments-cards--space tiles-container'>
                        <div className='form-item-row form--button-container'>
                            <Callout intent={Intent.PRIMARY}
                                     icon='office'
                                     className='card-caption bp3-text-large '
                                     title={this.props.vocabulary[created_impersonal_schools] + " " + this.props.impersonalSchools.length}
                            >
                                {this.props.impersonalSchools.length === 0 ? null :
                                    <div >
                                        <CSVLink
                                            data={this.props.impersonalSchools.toString()}
                                            filename='ImpersonalSchools.csv'
                                            className ='inline common-margin'
                                           /* headers={impersonalSchoolsCSV}*/>
                                            <Button
                                                icon='import'
                                                intent={Intent.PRIMARY}
                                                onClick={()=>{}}>
                                                {this.props.vocabulary[download_impersonal_schools]}
                                            </Button>
                                        </CSVLink>
                                        <Button
                                            className ='inline common-margin'
                                            icon='clipboard'
                                            intent={Intent.PRIMARY}
                                            onClick={this.handleCopyImpersonalIds}>
                                            { this.props.vocabulary[copy_ids]}
                                        </Button>
                                    </div>
                                }
                            </Callout>
                            {   this.state.hideImpersonalResponse ?
                                <Button
                                    className='card-caption-button'
                                    icon='expand-all'
                                    intent={Intent.SUCCESS}
                                    onClick={()=>{this.hideImpersonalResponse(false)}}/>
                                :
                                <Button
                                    className='card-caption-button'
                                    icon='collapse-all'
                                    intent={Intent.DANGER}
                                    onClick={()=>{this.hideImpersonalResponse(true)}}/>
                            }
                        </div>
                        {
                            this.state.hideImpersonalResponse ? null :
                            this.props.impersonalSchools.map((school, index) => (
                                <Card interactive={true}
                                      elevation={Elevation.ONE}
                                      key={index}
                                      className={'tiles-item form--item-container'}>
                                    {
                                        <div className='form-item-element' key={school}>
                                            <KeyValueViewer
                                                token={this.props.token}
                                                kv={new PlainTextKV(this.props.vocabulary[product_key], school)}
                                            />
                                        </div>
                                    }
                                </Card>))
                        }
                    </Card>
            }
            <MailSendTool
                recipients={this.state.recipients}
                close={this.handleCloseMail}
                onSuccess={this.handleMailSent}
                invalidCount={this.state.invalidCount}
                dataType={DataType.SchoolMail}
            />
            {
                this.state.mailResponse ?
                <MailSendResults
                    res={this.state.mailResponse}
                    close={this.handleCloseMailResult} />
                    : null
            }
        </Card>
    }
}

const mapStateToProps = (state: AppState) => ({
    schools : state.data.schools,
    schoolsMetric : state.data.schoolsMetric,
    token : state.user.token,
    vocabulary : state.data.vocabulary,
    impersonalSchools : state.data.impersonalSchools
});
export default connect(
    mapStateToProps
)(SchoolInstrument);