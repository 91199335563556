import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {sendRequest} from "./sendRequest";
import {Method, Request} from "../model/Request";
import {DataType} from "../actions/data";

// eslint-disable-next-line import/no-anonymous-default-export
export default(): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) =>{
    dispatch(sendRequest(new Request(undefined,
        DataType.ProductIdentities,Method.GET,getState().user.token), DataType.ProductIdentities));
}

