import {getTagArrayValues, getTagValue, S3TaggedObject, setTagValue} from "./S3";
import {IImageAdaptor, ImageAdaptor, ImageSize} from "../adaptors/ImageAdaptor";
import {FLAG} from "../adaptors/LanguageAdaptor";
import {Intent} from "@blueprintjs/core";
import store from "../store/store";
import {ProductIdentities} from "../model/AppData";

export const PRODUCT ="product";
export const WHATS_NEW ="whats-new";
export const TITLE = "title";
export const VERSION = "version";
export const MASTER = "master";
export const SCHOOL_MASTER ='school_master';
export const SCHOOL='school';
export const LANGUAGE = "language";
export const LANGUAGES = "languages";
export const OS = "os";

export interface ProductObjects {
    [key :string] :S3TaggedObject [];
}

export function getProductIdentities(): ProductIdentities {
    return store.getState().data.productIdentities;
}

export function getProductIdentity(product: string) : string {
    const productIdentities : ProductIdentities  = getProductIdentities();
    for(let key in productIdentities){
        if (key === product) return product;
        if(productIdentities[key].products.indexOf(product)>=0) {
            return key;
        }
    }
    return product;
}

export function getProductTitle(product : string) {
    try {
        const productIdentities : ProductIdentities  = getProductIdentities();
        return productIdentities[product].title;
    }catch (e) {
        console.log(product);
        return 'unknown';
    }

}

export function addIdentity(object : S3TaggedObject,objects : ProductObjects) {
    let product : string ="";
    if(getTagValue(MASTER,object.Tagging) && getTagValue(PRODUCT,object.Tagging)  === SCHOOL ){
        product = SCHOOL_MASTER;
    }else {
        product = getProductIdentity(getTagValue(PRODUCT,object.Tagging));
    }
    let thisProductObjects : S3TaggedObject [] = objects[product];
    if(thisProductObjects === undefined) {
        thisProductObjects = [];
        objects[product] = thisProductObjects;
    }
    setTagValue(PRODUCT,product,object.Tagging);
    thisProductObjects.push(object);
}

export function sortProductObjects(objects : ProductObjects) {
    console.log(objects);
    for(let key in objects){
        objects[key].sort((obj1,obj2)=>(obj2.date.getTime() - obj1.date.getTime()));
    }
}

export function getFilteredByOsAndLanguage (objects : ProductObjects, language : string|undefined = undefined, os : string| undefined =undefined) : ProductObjects{
    if(language === undefined && os === undefined ) return objects;
    const result : ProductObjects = {};
    const productIdentities : ProductIdentities  = getProductIdentities();
    Object.keys(objects)
        .sort((key1,key2)=>(productIdentities[key1].order - productIdentities[key2].order ))
        .forEach(key=>{
        const array=objects[key].filter(object=>{
            if(language !== undefined){
                const objectLanguage = getTagValue(LANGUAGE,object.Tagging);
                if(objectLanguage !== undefined && objectLanguage.toLocaleLowerCase() !== language.toLocaleLowerCase()) {
                    const otherLanguages = getTagArrayValues(LANGUAGES,object.Tagging,' ');
                    if (otherLanguages.indexOf(language) < 0) return  false;
                }
            }
            if(os !== undefined){
                const objectOs = getTagValue(OS, object.Tagging);
                if(objectOs  !== undefined && objectOs.toLocaleLowerCase() !== os.toLocaleLowerCase()) return false;
            }
            return true;
        });
        if (array.length > 0) result[key] = array;
    });
    return result;
}

export function additionalLanguagesMapper(val: string) : IImageAdaptor{
    return new ImageAdaptor(val+FLAG,val,val,Intent.SUCCESS,ImageSize.XTiny,()=>{},true);
}