export const VAR_DELIMITER = "_";
export const SLASH = "/";
export const MODE_JOIN = "join";
export const MODE_DOWNLOADS = "downloads";
export const SCHOOL = 'school';
export const MY = 'my';
export const DROZDOV = 'drozdov';
export const LOGOTALK = 'logotalk';
export const REGISTER = 'register'
export const COURSES ="courses";
export const SUBSCRIBERS ="subscribers";
export const MANAGER = 'manager';

export const RUSSIAN ='Russian';
export const ENGLISH = 'English';
export const FRENCH = 'French';
export const JAPANESE = 'Japanese';
export const GERMAN = 'German';
export const PORTO ='Porto';

export const ROOT = 'root';

export const STORED_AUTH ='authentication';
export const STORED_INSTRUMENTS = 'instruments';
export const STORED_SETTINGS = 'settings';
export const COVERED = 'covered';
export const STARTED = 'started';

export const REVIEW_STATUS  = 'review_status_';
export const REQUEST = 'request' ;
export const PROCESS = 'process' ;
export const REJECTED = 'rejected' ;
export const ACCEPTED = 'accepted' ;
export const REVIEW = 'review';
export const NONE = 'none';

export const OK = 'ok';

export const CODE_FORM_QUERY_PATTERN = '?email=';
export const MANAGER_REGISTER = '/manager/register';